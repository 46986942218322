import * as React from "react"
import Layout from "../components/layout"
import '../styles/styles.scss'
import 'react-multi-carousel/lib/styles.css';
import Linkedin from '../images/inline/linkedin.inline.svg'
import {useEffect, useRef, useState} from "react";
import Recaptcha from "react-recaptcha";

const ContactoPage = () => {
    let recaptchaInstance;
    const ContactForm = useRef();
    const executeCaptcha = () => {
        recaptchaInstance.execute();
    }
    const verifyCallback = () => {
        ContactForm.current && ContactForm.current.submit();
    }

    useEffect(() => {

        const handleShow = () => {
            ContactForm.current && ContactForm.current.reset()
        }

        window.addEventListener('pageshow', handleShow)

        return () => window.removeEventListener('pageshow', handleShow);
    }, [])

    return (
        <Layout>
            <Recaptcha
                elementID={'contactCaptcha'}
                ref={e => recaptchaInstance = e}
                sitekey="6LelaZQiAAAAANLAw-EwpD73EGatocoLeJdknTjx"
                size="invisible"
                verifyCallback={() => verifyCallback()}
                isolated={false}
            />
            <section className="contact-title">
                <h2 className="h1"
                    data-sal="slide-up'"
                    data-sal-duration={"600"}>Colaboremos juntos.</h2>
            </section>
            <section className="contact-information">
                <h3>Agenda tu asesoría sin costo aquí</h3>
                <div className="link mail">
                    <p>Correo electrónico</p>
                    <a href="mailto:contacto@topalemar.com.mx">contacto@tioalemar.com.mx</a>
                </div>
                <div className="link follow-us">
                    <p>Síguenos en</p>
                    <a href="https://www.linkedin.com/company/tecnologia-en-informacion-oportuna-alemar-sa-de-cv/" target={'_blank'}><Linkedin/></a>
                </div>
            </section>

            <form ref={ContactForm} className="contact-form form"
                  action={'https://getform.io/f/069413fd-b9da-4f06-a08f-0516838f0d43'} method={'POST'}>
                <input type="hidden" name={'subject'} value={'Me gustaría contactarme con Ustedes'}/>

                <div className="input col-2">
                    <label htmlFor="name">Nombre</label>
                    <input required type="text" name={'name'} id="name"/>
                </div>
                <div className="input">
                    <label htmlFor="email">Correo Electrónico</label>
                    <input required type="email" name={'email'} id="email"/>
                </div>
                <div className="input">
                    <label htmlFor="phone">Teléfono</label>
                    <input required type="tel" name={'phone'} id="phone"/>
                </div>
                <div className="input">
                    <label htmlFor="empresa">Compañia</label>
                    <input required type="text" name={'company'} id="empresa"/>
                </div>
                <div className="input">
                    <label htmlFor="position">Puesto</label>
                    <input required type="text" name={'position'} id="position"/>
                </div>
                <div className="input col-2">
                    <label htmlFor="message">Mensaje</label>
                    <textarea name="message" rows={1} id="message"/>
                </div>
                <button type={"button"} className="cyan small"
                        onClick={(event) => {
                            event.preventDefault();
                            ContactForm.current.reportValidity() && executeCaptcha()
                        }}>
                    <span>Enviar</span>
                </button>
            </form>

        </Layout>
    )
}

export default ContactoPage

export const Head = () => <title>T.I.O. ALEMAR | Contacto</title>
